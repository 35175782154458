const getters = {
  visitedViews: state => state.tagsView.visitedViews,
  initRouterData: state => state.routerData.initRouterData,
  btnLimits:state => {
    let isFlag = false
    let userInfo = state.userInfo
    let roleStatus = userInfo.roleList[0].itemController
    if(roleStatus){
      if(roleStatus == "1"){
        isFlag = true
      }
    }else{
      if(userInfo.account === "admin"){
        isFlag = true
      }
    }
    return isFlag
  }
}
export default getters
