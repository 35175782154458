<template>
  <div class="sidebar">
    <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        router
        background-color="rgba(255,255,255,.2)"
        text-color="#fff"
        active-text-color="#0014ff">
        <div v-for="(item,index) in dealRouter" :key="index">
            <el-menu-item v-if="item.path" :index="item.path">
                <i :class="item.meta.icon"></i>
                <span slot="title">{{item.meta.title}}</span>
            </el-menu-item>
            <el-submenu :index="item.inspect" v-else>
                <template slot="title">
                    <i :class="item.meta.icon"></i>
                    <span>{{item.meta.title}}</span>
                </template>
                <el-menu-item :index="subItem.path" v-for="subItem in item.children" :key="subItem.path">
                    <i :class="subItem.meta.icon"></i>
                    <span>{{subItem.meta.title}}</span>
                </el-menu-item>
            </el-submenu>
        </div>
    </el-menu>
  </div>
</template>

<script>
export default {
    data(){
        return{
            // 侧边栏展示，根据inspect和router的index.js 路由的father进行关联
            routerTitle:{
                base:{meta:{title:'系统服务管理',icon:'el-icon-setting'},inspect:'base',children:[]},
                trainManage:{meta:{title:'课目及评估指标模板',icon:'el-icon-bangzhu'},inspect:'trainManage',children:[]},
                infoImport:{meta:{title:'学员信息录入',icon:'el-icon-tickets'},inspect:'infoImport',children:[]},
                dataDeal:{meta:{title:'训练成绩综合评估',icon:'el-icon-s-data'},inspect:'dataDeal',children:[]},
                trainingService:{meta:{title:'课目训练服务',icon:'el-icon-timer'},inspect:'trainingService',children:[]},
                trainAnalyze:{meta:{title:'训练成绩数据可视化',icon:'el-icon-pie-chart'},inspect:'trainAnalyze',children:[]},
                // demo:{meta:{title:'数据备份管理',icon:'el-icon-menu'},inspect:'demo',children:[]},
                // dataAnalysis:{meta:{title:'大数据分析',icon:'el-icon-data-analysis'},inspect:'dataAnalysis',children:[]}
                transmit:{meta:{title:'在线靶场数据传输',icon:'el-icon-printer'},inspect:'transmit',children:[]},
                merge:{meta:{title:'惯导加UWB数据融合',icon:'el-icon-connection'},inspect:'merge',children:[]},
                
            },
            dealRouter:[]
        }
    },
    mounted(){
        // console.log(this.$store.state)
        let roleaLabel = this.$store.state.userInfo
        if(roleaLabel.account !== "admin"){     //管理员访问所有路由
            this.initOtherRouter(JSON.parse(roleaLabel.roleList[0].menu))
        }else{
            this.initRouter()
        }
        // this.initRouter()
    },
    methods:{
        // 添加侧边栏数据
        initRouter(){
            this.dealRouter = []
            const routerArr = this.$router.options.routes.filter(item=>item.path === "/")[0]
            // console.log(routerArr);
            routerArr.children.forEach(item=>{
                if(item.meta.father){
                    let findFlag = this.dealRouter.find(item1=>item1.inspect === item.meta.father)
                    if(findFlag){
                        findFlag.children.push(item)
                    }else{
                        this.routerTitle[item.meta.father].children.push(item)
                        this.dealRouter.push(this.routerTitle[item.meta.father])
                    }
                }else{
                    if(!item.meta.noFater){
                        this.dealRouter.push(item)
                    }
                }
            })
            this.$store.commit("GET_ROUTER_DATA",this.dealRouter)  //初始的所有路由
            // console.log(this.dealRouter);
            // 如果所设置的角色，就需要根据角色的进行赋值
            if(false){
                this.dealRouter = []
            }
        },
        // 添加侧边栏数据
        initOtherRouter(arr){
            this.dealRouter = []
            arr.forEach(item=>{
                if(item.meta.father){
                    let findFlag = this.dealRouter.find(item1=>item1.inspect === item.meta.father)
                    if(findFlag){
                        findFlag.children.push(item)
                    }else{
                        this.routerTitle[item.meta.father].children.push(item)
                        this.dealRouter.push(this.routerTitle[item.meta.father])
                    }
                }else{
                    if(!item.meta.noFater){
                        this.dealRouter.push(item)
                    }
                }
            })
            this.$store.commit("GET_ROUTER_DATA",this.dealRouter)  //初始的所有路由
            
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebar{
    overflow: auto;
}
::v-deep .el-submenu__title,
.el-menu-item{
    font-size:18px;
    i{
        color:#fff;
    }
}
.el-submenu .el-menu-item{
    font-size:16px;
}
</style>