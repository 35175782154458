import request from "./request"

const training = {
	// 获取训练场次列表
	trainingList(params){
		return request({
		    url: "/training-plant/query",
		    method: "get",
		    params
		})
	},
	// 创建训练场次
	add(data){
		return request({
		    url: "/training-plant/create",
		    method: "post",
		    data
		})
	},
	// 删除训练场次
	delete(data){
		return request({
		    url: "/training-plant/delete",
		    method: "delete",
		    data
		})
	},
	// 更新训练场次
	edit(data){
		return request({
		    url: "/training-plant/update",
		    method: "put",
		    data
		})
	},
	detail(data){
		return request({
		    url: "/training-plant/load",
		    method: "post",
		    data
		})
	},


	// 获取训练场次数据列表
	trainingDataList(params){
		return request({
		    url: "/training-data/query",
		    method: "get",
		    params
		})
	},
	// 创建训练场次数据
	addData(data){
		return request({
		    url: "/training-data/create",
		    method: "post",
		    data
		})
	},
	// 删除训练场次数据
	deleteData(data){
		return request({
		    url: "/training-data/delete",
		    method: "delete",
		    data
		})
	},
	// 更新训练场次数据
	editData(data){
		return request({
		    url: "/training-data/update",
		    method: "put",
		    data
		})
	},
	// 训练场数据数据详情
    detailData(data){
		return request({
		    url: "/training-data/load",
		    method: "post",
		    data
		})
	},

	// 影响训练因素分析
	tree(data){
		return request({
		    url: "/training/create-regression-tree",
		    method: "post",
		    data
		})
	},
}
export default training