import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/permission' // 路由 control
// import gojs from 'gojs'
// Vue.prototype.go = gojs
// 公共样式
import '@/assets/css/public.css'

// 引入utils工具
import utils from './utils/utils'
Vue.prototype.$utils = utils

//element-ui样式引入
import 'element-ui/lib/theme-chalk/index.css'
//element-ui文件夹下
import element from './plugins/element'
Vue.use(element)
// 图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
  'inline': false,
  'button': false,
  'navbar': false,
  'title': false,
  'toolbar': false,
  'tooltip': false,
  'movable': false,
  'zoomable': false,
  'rotatable': false,
  'scalable': false,
  'transition': false,
  'fullscreen': false,
  'keyboard': false,
  'url': 'data-source'
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
